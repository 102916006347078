'use client';

import { useState } from 'react';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { createClient } from '@/utils/supabase/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { TypographyH2, TypographyP } from '@/components/ui/typography';
import MainWrapper from '@/components/MainWrapper';

const formSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
});

type FormData = z.infer<typeof formSchema>;

const AnimatedElement = ({
  children,
  className,
  delay = 0,
}: {
  children: React.ReactNode;
  className?: string;
  delay?: number;
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    className={className}
  >
    {children}
  </motion.div>
);

export default function Login() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') || '/';
  const supabase = createClient();
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const onSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);
      const { error } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

      if (error) throw error;
      router.push(redirectTo);
      router.refresh();
    } catch (err: unknown) {
      toast.error(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOAuthSignIn = async (provider: 'github' | 'discord') => {
    try {
      setIsLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
          queryParams: {
            redirectTo: redirectTo,
          },
        },
      });

      if (error) throw error;
    } catch (err: unknown) {
      toast.error(err instanceof Error ? err.message : 'An unexpected error occurred');
      setIsLoading(false);
    }
  };

  return (
    <MainWrapper className='flex-none'>
      <section className='relative flex flex-col items-center justify-center px-4 py-16 pt-48 pb-16 sm:px-6 lg:px-8 overflow-hidden'>
        {/* Background elements */}
        <div className='absolute inset-0 z-0 overflow-hidden'>
          <div className='absolute -top-40 -right-40 h-80 w-80 rounded-full bg-blue-500/10 blur-3xl' />
          <div className='absolute -bottom-40 -left-40 h-80 w-80 rounded-full bg-purple-500/10 blur-3xl' />
          <div className='absolute inset-0 bg-grid-small-black/[0.2]' />
        </div>

        <div className='relative z-10 w-full max-w-md mx-auto'>
          <AnimatedElement delay={0.3}>
            <div className='rounded-xl border border-white/10 bg-white/5 p-8 backdrop-blur-md shadow-xl'>
              <TypographyH2 className='mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600'>
                Sign in
              </TypographyH2>

              <AnimatedElement delay={0.5}>
                <TypographyP className='mb-6 text-gray-300'>
                  Choose your preferred sign in method
                </TypographyP>
              </AnimatedElement>

              <AnimatedElement delay={0.7} className='grid grid-cols-2 gap-6 mb-6'>
                <Button
                  variant='outline'
                  className='inline-flex items-center justify-center gap-2 rounded-md border-white/10 bg-white/5 backdrop-blur-sm hover:bg-white/10 hover:border-white/20 transition-all'
                  disabled={isLoading}
                  onClick={() => handleOAuthSignIn('github')}
                >
                  <Icons.gitHub className='mr-2 h-4 w-4' />
                  Github
                </Button>
                <Button
                  variant='outline'
                  className='inline-flex items-center justify-center gap-2 rounded-md border-white/10 bg-white/5 backdrop-blur-sm hover:bg-white/10 hover:border-white/20 transition-all'
                  disabled={isLoading}
                  onClick={() => handleOAuthSignIn('discord')}
                >
                  <Icons.discord className='mr-2 h-4 w-4' />
                  Discord
                </Button>
              </AnimatedElement>

              <AnimatedElement delay={0.8} className='relative mb-6'>
                <div className='absolute inset-0 flex items-center'>
                  <span className='w-full border-t border-white/10'></span>
                </div>
                <div className='relative flex justify-center text-xs uppercase'>
                  <span className='px-2 text-white/60 bg-black/40 backdrop-blur-sm rounded-full'>
                    OR CONTINUE WITH
                  </span>
                </div>
              </AnimatedElement>

              <AnimatedElement delay={0.9}>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
                    <FormField
                      control={form.control}
                      name='email'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className='text-gray-200'>Email</FormLabel>
                          <FormControl>
                            <div className='relative'>
                              <Icons.mail
                                className='absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400'
                                size={20}
                              />
                              <Input
                                id='email'
                                className='pl-10'
                                placeholder='name@example.com'
                                autoComplete='username'
                                {...field}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name='password'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className='text-gray-200'>Password</FormLabel>
                          <FormControl>
                            <div className='relative'>
                              <Icons.lock
                                className='absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400'
                                size={20}
                              />
                              <Input
                                id='password'
                                type='password'
                                className='pl-10'
                                placeholder='••••••••'
                                autoComplete='current-password'
                                {...field}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button
                      className='w-full glow-bg bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700'
                      type='submit'
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                          Signing In...
                        </>
                      ) : (
                        'Sign In'
                      )}
                    </Button>
                  </form>
                </Form>
              </AnimatedElement>

              <AnimatedElement delay={1.0} className='mt-6 text-center text-sm text-gray-300'>
                Don't have an account?{' '}
                <Link
                  href='/register'
                  className='text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 hover:underline'
                >
                  Sign up
                </Link>
              </AnimatedElement>
            </div>
          </AnimatedElement>

          {/* Floating elements */}
          <motion.div
            className='absolute -top-10 -right-10 h-16 w-16 rounded-full bg-blue-500/20 backdrop-blur-md hidden md:block'
            animate={{ y: [0, 15, 0] }}
            transition={{ repeat: Infinity, duration: 4, ease: 'easeInOut' }}
          />
          <motion.div
            className='absolute -bottom-10 -left-10 h-24 w-24 rounded-full bg-purple-500/20 backdrop-blur-md hidden md:block'
            animate={{ y: [0, -20, 0] }}
            transition={{ repeat: Infinity, duration: 5, ease: 'easeInOut', delay: 1 }}
          />
        </div>
      </section>
    </MainWrapper>
  );
}
