import React from 'react';

import { cn } from '@/lib/utils';

interface TypographyProps {
  children: React.ReactNode;
  className?: string;
}

export function TypographyH1({ children, className }: TypographyProps) {
  return <h1 className={cn('text-h1-sm md:text-h1 font-bold', className)}>{children}</h1>;
}

export function TypographyH2({ children, className }: TypographyProps) {
  return <h2 className={cn('text-h2-sm md:text-h2 font-semibold', className)}>{children}</h2>;
}

export function TypographyH3({ children, className }: TypographyProps) {
  return <h3 className={cn('text-h3-sm md:text-h3 font-semibold', className)}>{children}</h3>;
}

export function TypographyP({ children, className }: TypographyProps) {
  return <p className={cn('text-body-sm md:text-body', className)}>{children}</p>;
}

export function TypographySmall({ children, className }: TypographyProps) {
  return <p className={cn('text-small-sm md:text-small text-gray-400', className)}>{children}</p>;
}

export function TypographyLarge({ children, className }: TypographyProps) {
  return <div className={cn('text-h3-sm md:text-h3', className)}>{children}</div>;
}

export function TypographyDisplay({ children, className }: TypographyProps) {
  return <h1 className={cn('text-display-sm md:text-display font-bold', className)}>{children}</h1>;
}
