import { Github, Loader2, Lock, Mail, MessageSquare } from 'lucide-react';

export const Icons = {
  spinner: Loader2,
  gitHub: Github,
  discord: MessageSquare,
  mail: Mail,
  lock: Lock,
};

export type Icon = keyof typeof Icons;
